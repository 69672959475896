import React from "react";

export default function Tournament() {
    return (
        <section className="py-10 md:py-16" id="tournmt">
            <div className="container">
                <div className="card lg:card-side bg-base-100 shadow-xl">
                    <figure><img src="/assets/aleanfamilysputnik.jpg" alt="Hotel Alean Family Sputnik" /></figure>
                    <div className="card-body">
                        <p className="font-bold text-2xl">Место проведения</p>
                        <p className="text-xl">Деловой зал с видом на море <br />отеля "Alean Family Sputnik"</p>
                        <div className="card-actions justify-end">
                            <div className="badge badge-error text-lg">21 ноября</div>
                            <div className="badge badge-accent text-lg">г. Сочи</div>
                        </div>
                    </div>
                </div>
                <div className="text-center pt-4">
                    <h2 className="text-3xl sm:text-5xl font-bold mx-4">
                        Компетенции
                    </h2>
                    <p className="text-lg sm:text-2xl">
                        Открыт прием заявок на <br />Всероссийский турнир
                    </p>
                    <p className="text-3xl sm:text-5xl text-primary hover:text-red-600 font-bold mb-4">
                        "Мастера красоты"
                    </p>
                    <p className="p-0 text-lg sm:text-2xl mb-6">
                        <font className="text-secondary">Скидка на участие 20% только до 1 ноября</font><br />
                        <small>* стоимость на сайте указана без скидки</small>
                    </p>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 lg:gap-8 xl:gap-10">
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/14.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">

                                <h2 className="card-title">Парикмахерское искусство</h2>
                                <p>
                                    Креативный образ. Полный модный образ. Брейдинг.
                                </p>
                            </div>
                        </div>
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/15.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">
                                <h2 className="card-title">Визаж</h2>
                                <p>
                                    Креативные стрелки.
                                </p>
                            </div>
                        </div>
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/17.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">
                                <h2 className="card-title">Ногтевой сервис</h2>
                                <p>
                                    Салонный маникюр. Дизайн гель-лаком.
                                </p>
                            </div>
                        </div>
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/12.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">
                                <h2 className="card-title">Массаж</h2>
                                <p>
                                    Классический. Свободные руки.
                                </p>
                            </div>
                        </div>
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/11.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">
                                <h2 className="card-title">Косметология</h2>
                                <p>
                                    Уход за кожей лица и шеи.
                                </p>
                            </div>
                        </div>
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/13.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">
                                <h2 className="card-title">Депиляция</h2>
                                <p>
                                    Мастер депиляции
                                </p>
                            </div>
                        </div>
                        <div className="card bg-base-200 transform-gpu transitions-all duration-300 hover:-translate-y-2 hover:shadow-lg">
                            <figure><img src="/assets/tournmt/18.jpg" alt="Shoes" /></figure>
                            <div className="card-body items-center text-center gap-4">
                                <h2 className="card-title">Ресницы/Брови</h2>
                                <p>
                                    Классическое наращивание. Ламинирование. Объемное наращивание ресниц. Архитектура и долговременная укладка бровей.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="join join-vertical mt-4">
                        <div className="join-item py-7 badge badge-error badge-outline card-title">
                            Стоимость одной номинации - 15 000 рублей
                        </div>
                        <div className="join-item py-7 badge badge-error badge-outline card-title">
                            <font className="mx-2">
                                Стоимость двух номинаций - 27 000 рублей
                            </font>
                        </div>
                        <div className="join-item py-7 my-4 mr-7 badge badge-error badge-outline card-title">
                            <font className="mx-2">
                                Стоимость трёх номинаций - 35 000 рублей
                            </font>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}