import React from "react";

const THEMES = ["light","dark","cupcake","bumblebee","emerald","corporate","synthwave","retro","cyberpunk","valentine","halloween","garden","forest","aqua","lofi","pastel","fantasy","wireframe","black","luxury","dracula","cmyk","autumn","business","acid","lemonade","night","coffee","winter",];

export default function Header() {
    const [theme, setTheme] = React.useState('night');

    React.useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme])

    const handleThemeChange = (e) => {
        var val = e.target.getAttribute('data-set-theme');
        setTheme(val);
    }
    return (
        <header className="sticky top-0 z-50 h-px bg-base-100">
            <div className="container">
            <div className="navbar bg-base-100">
                <div className="navbar-start">
                    <div className="dropdown">
                        <button tabIndex={0} className="btn btn-primary btn-circle lg:hidden mr-1">
                            <i className="bi bi-list text-2xl"></i>
                        </button>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-1 p-2 shadow bg-base-100 rounded-box w-52">
                            <li><a href='#tournmt'>О турнире</a></li>
                            <li><a href='#testmn'>Рекомендации</a></li>
                            <li><a href='#bonus'>Премия</a></li>
                            <li><a href='#qa'>Контакты</a></li>
                        </ul>
                    </div>
                    <a className="btn btn-ghost normal-case text-lg" href="#hero">Турнир Бьюти Мастеров</a>
                </div>
                <div className="navbar-center hidden lg:flex pl-24">
                    <ul className="menu menu-horizontal p-0 font-medium">
                        <li><a href='#tournmt'>О турнире</a></li>
                        <li><a href='#testmn'>Рекомендации</a></li>
                        <li><a href='#bonus'>Премия</a></li>
                        <li><a href='#qa'>Контакты</a></li>
                    </ul>
                </div>
                <div className="navbar-end">
                <a className="btn" href="https://saratovsegodnya.ru/form-sochi-23/">Подать заявку</a>
                    <div className="dropdown dropdown-end">
                        <button tabIndex={0} className="btn">Темы ({THEMES.length})</button>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-1 p-2 shadow bg-base-100 rounded-box w-52 max-h-96 overflow-y-auto">
                            {
                                THEMES.map((theme, i) => <li key={theme + i}><button data-set-theme={theme} onClick={handleThemeChange}>{i + 1 + '. ' + theme}</button></li>)
                            }
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </header>
    )
}