import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css';

import Header from './components/Header';
import Hero from './components/Hero';
import Tournament from './components/Tournament';
import Testimonial from './components/Testimonial';
import QA from './components/QA';
import Footer from './components/Footer';
import Bonus from './components/Bonus';

function App() {
  return (
  <>
    <Header />
    <Hero />
    <Tournament />
    <Testimonial />
    <Bonus />
    <QA />
    <Footer />
    
  </>
  );
}

export default App;
