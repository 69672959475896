import React from "react";

export default function Bonus() {
    return (
        <section className="py-10 md:py-16" id="bonus">
            <div className="container">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl font-bold mb-4">
                        Премия "Actually brilliant persons"
                    </h2>
                    <div className="card lg:card-side bg-base-100 shadow-xl">
                        <figure><img className="h-96" src="/assets/bounse_photo1.jpg" alt="Премия Actually brilliant persons" /></figure>
                        <div className="card-body">
                            <p className="font-bold text-2xl">
                                В рамках турнира пройдет премия 
                            </p>
                            <p className="text-xl">Участие в премии - это не только получение признания <br />
                            от экспертов в разных профессиональных областях, <br />но и публичный жест яркой самодостаточной личности, <br />
                            от которого зависит позиционирование компании <br />и ее руководителя, 
                            а также укрепление личного бренда.</p>
                            <p className="text-primary text-xl">Если Вы владелец своей студии, школы, салона, магазина, <br />производитель товаров и услуг - уточните подробности <br />у организаторов мероприятия!</p>
                        </div>
                    </div>
                    <h3 className="text-3xl sm:text-5xl font-bold pt-4 mb-4">
                        Номинации
                    </h3>
                    <div className="grid grid-cols-2 py-2">
                        <input className="btn" type="radio" name="options" aria-label="Лучшая школа/учебный центр" />
                        <input className="btn" type="radio" name="options" aria-label="Лучшая студия/салон" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший СПА-салон" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший профессиональный магазин/сеть магазинов" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший бренд" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший руководитель" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший тренер" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший преподаватель" />
                        <input className="btn" type="radio" name="options" aria-label="Лучший инструктор" />
                        <input className="btn" type="radio" name="options" aria-label="Инноватор в профессии" />
                        <input className="btn" type="radio" name="options" aria-label="Прорыв года" />
                        <input className="btn" type="radio" name="options" aria-label="Перспектива года" />
                    </div>
                    <p className="text-fuchsia-600 text-xl">Стоимость одной номинации - <span className="text-rose-600">15 000 рублей</span>.</p>
                </div>
            </div>
        </section>
    )
}