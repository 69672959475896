import React from "react";

export default function Testimonial() {
    return (
        <section className="py-10 md:py-16 bg-neutral" id="testmn">
            <div className="container">
                <div className="text-center text-white">
                    <h2 className="text-3xl sm:text-5xl font-bold mb-4">
                        Отзывы с прошлого турнира
                    </h2>
                    <p className="text-lg sm:text-2xl mb-6 md:mb-14">
                        Мы создаем вдохновляющий опыт, красивый и инновационный.
                    </p>
                </div>
                <div className="columns-1 sm:columns-2 lg:columns-3 gap-6 lg:gap-8">
                    <div className="block mb-8 lg:mb-10">
                        <div className="stack">
                            <div className="card bg-secondary text-secondary-content">
                                <div className="card-body gap-4">
                                    <div className="flex items-center">
                                        <div className="avatar flex-shrink-0">
                                            <div className="w-16 rounded-full border-2 border-white">
                                                <img src="https://i.pravatar.cc/150?img=25" alt="" />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <p className="text-lg font-medium leading-tight">Татьяна</p>
                                            <small>Парикмахер</small>
                                        </div>
                                    </div>

                                    <p>
                                        Доброе утро. Я была в качестве гостя. Пожалела, что не приняла участие. Но ничего, в следующий раз обязательно поучаствую. Много полезной информации для себя почерпнула. Новые знакомства, новые знания заряжают и вдохновляют на творчество.
                                    </p>
                                </div>
                            </div>
                            <div className="card bg-primary h-10"></div>
                            <div className="card bg-warning h-10"></div>
                        </div>
                    </div>
                    <div className="block mb-8 lg:mb-10">
                        <div className="stack">
                            <div className="card bg-secondary text-secondary-content">
                                <div className="card-body gap-4">
                                    <div className="flex items-center">
                                        <div className="avatar flex-shrink-0">
                                            <div className="w-16 rounded-full border-2 border-white">
                                                <img src="https://i.pravatar.cc/150?img=5" alt="" />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <p className="text-lg font-medium leading-tight">Елена Горшкова</p>
                                            <small>Бровист</small>
                                        </div>
                                    </div>

                                    <p>
                                        Это были лучшие командировочные выходные за последнее время!!! Столько эмоций и впечатлений, и конечно же, печаль о том, что все закончилось, есть такое чувство неудовлетворенности, что не осталась на дискошоу вечеринку!!!! <span className="text-primary">к сожалению😔</span> Я благодарю судьбу, что сводит меня с нужными людьми, и отсеивает ненужных😉 отдельное спасибо наставнику Анечке Сельченко, что дает взбучку и никогда не дает слабеть 💪❤️спасибо Олечке Ершовой за то что рядом!❤️ и спасибо организаторам за эти суперкрутые 2 дня 🥰спасибо всем!!!
                                    </p>

                                </div>
                            </div>
                            <div className="card bg-primary h-10"></div>
                            <div className="card bg-warning h-10"></div>
                        </div>
                    </div>
                    <div className="block mb-8 lg:mb-10">
                        <div className="stack">
                            <div className="card bg-secondary text-secondary-content">
                                <div className="card-body gap-4">
                                    <div className="flex items-center">
                                        <div className="avatar flex-shrink-0">
                                            <div className="w-16 rounded-full border-2 border-white">
                                                <img src="https://i.pravatar.cc/150?img=9" alt="" />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <p className="text-lg font-medium leading-tight">Аревик Шахян</p>
                                            <small>Бровист</small>
                                        </div>
                                    </div>

                                    <p>
                                        Я в диком восторге!!!😍 Выплеск положительных эмоций, приятная атмосфера, профессиональные люди, компетентные судьи, замечательные и шоу, и мастерклассы. Победные места и самое главное - сертификат на чемпионат в Москву😍🥰 <span className="text-fuchsia-600">СПАСИБО ОГРОМНОЕ, организаторы!!!</span>
                                    </p>

                                </div>
                            </div>
                            <div className="card bg-primary h-10"></div>
                            <div className="card bg-warning h-10"></div>
                        </div>
                    </div>
                    <div className="block mb-8 lg:mb-10">
                        <div className="stack">
                            <div className="card bg-secondary text-secondary-content">
                                <div className="card-body gap-4">
                                    <div className="flex items-center">
                                        <div className="avatar flex-shrink-0">
                                            <div className="w-16 rounded-full border-2 border-white">
                                                <img src="https://i.pravatar.cc/150?img=10" alt="" />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <p className="text-lg font-medium leading-tight">Оля Сергеева</p>
                                            <small>Гость фестиваля</small>
                                        </div>
                                    </div>

                                    <p>
                                        Я была гостем мероприятия... Заряд положительных эмоций, полезные мастер классы, опытные мастера своего дела поразили профессионализмом. Атмосфера сказочная, очень круто все организовано... Не пожалела, что сходила... Обязательно буду посещать и другие проекты, возможно и в качестве участника... И ещё плюс большой это мотивация двигаться, не сидеть на месте, а стремиться к высотам... Спасибо организаторам, что подарили незабываемые впечатления ❤️❤️❤️👍
                                    </p>

                                </div>
                            </div>
                            <div className="card bg-primary h-10"></div>
                            <div className="card bg-warning h-10"></div>
                        </div>
                    </div>
                    <div className="block mb-8 lg:mb-10">
                        <div className="stack">
                            <div className="card bg-secondary text-secondary-content">
                                <div className="card-body gap-4">
                                    <div className="flex items-center">
                                        <div className="avatar flex-shrink-0">
                                            <div className="w-16 rounded-full border-2 border-white">
                                                <img src="https://i.pravatar.cc/150?img=26" alt="" />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <p className="text-lg font-medium leading-tight">Наталья Кочеткова</p>
                                            <small>Мастер депиляции</small>
                                        </div>
                                    </div>

                                    <p>
                                        Большое спасибо, огромное! организаторам турнира, судьям! Я здесь получила новую себя! Эмоций море! Вера в себя, победа над собой! Вышла из зоны комфорта, чтобы идти, бежать дальше! В меня поверили!</p>

                                </div>
                            </div>
                            <div className="card bg-primary h-10"></div>
                            <div className="card bg-warning h-10"></div>
                        </div>
                    </div>
                    <div className="block mb-8 lg:mb-10">
                        <div className="stack">
                            <div className="card bg-secondary text-secondary-content">
                                <div className="card-body gap-4">
                                    <div className="flex items-center">
                                        <div className="avatar flex-shrink-0">
                                            <div className="w-16 rounded-full border-2 border-white">
                                                <img src="https://i.pravatar.cc/150?img=27" alt="" />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <p className="text-lg font-medium leading-tight">Виктория Кайро</p>
                                            <small>Парикмахер</small>
                                        </div>
                                    </div>

                                    <p>
                                        Хочу поделиться впечатлениями от турнира.
                                        Для меня это первый опыт участия в таком масштабном мероприятии и выход из зоны комфорта. Все два дня мой организм сопротивлялся как мог, поднялась температура, пропадал голос. Мой организм кричал: Зачем тебе это нужно! Оставайся дома! В таком состоянии идти нельзя!
                                        Но я твердо решила для себя участвовать и ни разу не пожалела. Теперь у меня появилась вера в себя и уверенность, что двигаюсь в правильном направлении).
                                        Для Саратова это очень крутое мероприятие. Эмоции зашкаливают. Организаторам огромное спасибо. За короткий срок вы сделали невозможное. Ещё раз огромное спасибо ♥️.
                                        Отдельное спасибо хочу сказать Лане Никифоровой, именно она сдвинула меня с мертвой точки, поддержала и направляет всегда в нужную сторону♥️♥️♥️♥️
                                    </p>
                                </div>
                            </div>
                            <div className="card bg-primary h-10"></div>
                            <div className="card bg-warning h-10"></div>
                        </div>
                    </div>
                </div>
                <div className="carousel w-full pt-10">
  <div id="slide1" className="carousel-item relative w-full">
    <img src="/assets/tournmt/c-1.jpg" className="w-full" alt="фотка с турнира 1" />
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide4" className="btn btn-circle">❮</a> 
      <a href="#slide2" className="btn btn-circle">❯</a>
    </div>
  </div> 
  <div id="slide2" className="carousel-item relative w-full">
    <img src="/assets/tournmt/c-2.jpg" className="w-full" alt="фотка с турнира 2"/>
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide1" className="btn btn-circle">❮</a> 
      <a href="#slide3" className="btn btn-circle">❯</a>
    </div>
  </div> 
  <div id="slide3" className="carousel-item relative w-full">
    <img src="/assets/tournmt/c-3.jpg" className="w-full" alt="фотка с турнира 3"/>
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide2" className="btn btn-circle">❮</a> 
      <a href="#slide4" className="btn btn-circle">❯</a>
    </div>
  </div> 
  <div id="slide4" className="carousel-item relative w-full">
    <img src="/assets/tournmt/c-4.jpg" className="w-full" alt="фотка с турнира 4"/>
    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
      <a href="#slide3" className="btn btn-circle">❮</a> 
      <a href="#slide1" className="btn btn-circle">❯</a>
    </div>
  </div>
</div>
            </div>
        </section>
    )
}