import React from "react";

export default function QA() {
    return(
        <section className="py-6" id="qa">
            <div className="container">
                <div className="flex flex-col md:flex-row justify-center items-center gap-6 text-center">
                    <span className="flex-grow text-2xl md:text-5xl xl:text-4xl text-primary">
                        Остались вопросы?<br />
                        <small className="text-secondary">Руководитель оргкомитета<br />
                        <i className="bi bi-telephone-inbound" /> {'\u00A0'}
                        8(917)321 24 15
                        </small>
                    </span>
                    <p>
                        
                    </p>
                    <a href="https://api.whatsapp.com/send?phone=79173212415&text=Здравствуйте, у меня вопросы по турниру мастеров" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-secondary rounded-full h-40 w-40"><i className="bi bi-whatsapp text-9xl" /></button>
                    </a>
                </div>
            </div>
        </section>
    )
}