import React from "react";

export default function Hero() {
    return (
        <section id="hero">
            <div className='container'>
                <div className="hero h-96 md:h-[500px] rounded-box overflow-hidden" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/tournmt/bg.jpg'})` }}>
                    <div className="hero-overlay bg-opacity-60 bg-700"></div>
                    <div className="hero-content text-center text-secondary-content">
                        <div className="max-w-lg">
                            <h1 className="mb-5 sm:mb-7 text-4xl sm:text-5xl text-white">
                                "Мастера Красоты"
                            </h1>
                            <p className="mb-5 sm:mb-7 text-2xl sm:text-lg font-bold text-white">Открытый трансформационный турнир мастеров индустрии красоты и здоровья</p>
                            <a className="btn btn-secondary sm:btn-wide" href="https://saratovsegodnya.ru/form-sochi-23/">Подать заявку</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}